import React from "react"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { Grid } from '@material-ui/core'
import Layout from "../components/layout"
import theme from "../components/theme"
import Entry from "../components/entry"
import '../styles/index.scss'
import Header from "../components/header"
import Top from "../components/top"
import FloatCard from "../components/drawer"

import landing from '../styles/imgs/tribrs/home.png'
import store from '../styles/imgs/tribrs/store.jpg'
import store2 from '../styles/imgs/tribrs/store2.jpg'
import office from '../styles/imgs/tribrs/office.png'
import checkout from '../styles/imgs/tribrs/checkout.png'

export default function Nomad() {
    return <MuiThemeProvider theme={theme}>
        <Layout>
            <Header title="Tribrs" />
            <Top />
            <div className="main-container">
                <FloatCard />
                <main className="content">


                    <Grid container justify="center" className="container-entries">
                        <Grid item xs={10}>
                            <h1>Tribrs</h1>
                        </Grid>
                        <Grid item container xs={12} sm={10} justify="space-between">
                            <Grid item xs={12} sm={12}>
                                <Entry
                                    title="Summary"
                                    desc={["Foreign startup (associated with Nomad Card City) which aims at providing cashback rewards for customers through a Shopify app for online stores. All the Front-End was developed by myself.",
                                        "Online stores do not have to worry about managing rewards for their customers with Tribrs, they just need to install our Shopify plugin/add-on and Tribrs automatically creates a cashback system for users registered in the store as well as new customers. All possible thanks to integrations using Shopify's development tools.",
                                        "An iframe appears at the store and also in the checkout phase to ensure users earn cash back with their purchases while becoming loyal customers."]}
                                    date=""
                                    altV
                                /* image={react} */
                                />
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid container justify="center" className="container-images">
                        <Grid item xs={10}>
                            <h2>Desktop</h2>
                        </Grid>
                        <Grid container justify="center" item xs={12} sm={10}>
                            <img src={landing} className="image-box" alt={"Landing Page Nomad Card"} />
                        </Grid>
                        <Grid container justify="center" item xs={12} sm={10}>
                            <img src={store} className="image-box" alt={"Landing Page Listings Nomad Card"} />
                        </Grid>
                        <Grid container justify="center" item xs={12} sm={10}>
                            <img src={store2} className="image-box" alt={"Level view Nomad Card"} />
                        </Grid>
                        <Grid container justify="center" item xs={12} sm={10}>
                            <img src={office} className="image-box" alt={"Map view Nomad Card"} />
                        </Grid>
                        <Grid container justify="center" item xs={12} sm={10}>
                            <img src={checkout} className="image-box" alt={"Map view Nomad Card"} />
                        </Grid>
                    </Grid>



                </main>
            </div>

            {/* Waves SVG */}
            <svg className="wave-top" viewBox="0 0 1439 147" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-1.000000, -14.000000)" fillRule="nonzero">
                        <g className="wave" fill="#FFF">
                            <path d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"></path>
                        </g>
                        <g transform="translate(1.000000, 15.000000)" fill="#FFF">
                            <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
                                <path d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496" opacity="0.100000001"></path>
                                <path d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z" opacity="0.100000001"></path>
                                <path d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z" opacity="0.200000003"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </Layout>
    </MuiThemeProvider>
}
